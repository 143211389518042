<template>
  <section class="mx-2">
    <v-card flat>
      <v-card-title>
        <span class="friendly-2">{{ $store.getters.doGetUser.fullName }}'s Dashboard</span>
      </v-card-title>
      <v-card-subtitle>
        <span class="friendly-1">{{ userRolesTitle }}</span>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col md="4">
            <v-card style="height: 100%;">
              <v-overlay absolute :value="hideAdmin" opacity="0.2">
                <span class="friendly-1">This is not accessible to you.</span>
              </v-overlay>
              <v-card-text :class="hideAdmin ? 'blurry' : ''">
                <div class="friendly-2 mb-4">User Sessions</div>
                <VueApexCharts :class="userSessions.isLoading ? 'blurry' : ''" ref="userSessionChart" type="line" :options="userSessions.options" :series="userSessions.series" :theme="userSessions.options.chartTheme"></VueApexCharts>
                <v-progress-circular v-if="userSessions.isLoading" indeterminate color="accent" :size="50" :width="5" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></v-progress-circular>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="3">
            <padi-certifcation-lookup></padi-certifcation-lookup>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <all-transactions-table @showTransaction="doGoToTransaction"></all-transactions-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import AllTransactionsTable from '@/views/admin/transactions/allTransactionsTable.vue'
import VueApexCharts from 'vue-apexcharts'
import PadiCertifcationLookup from '@/components/tools/padiCertifcationLookup.vue'
export default {
  name: 'Admin-Home',
  components: { PadiCertifcationLookup, AllTransactionsTable, VueApexCharts },
  data: () => ({
    isLoading: false,
    hideAdmin: true,
    roles: [],
    userSessions: {
      isLoading: false,
      options: {
        stroke: {
          curve: 'smooth'
        },
        chart: {
          background: 'transparent',
          id: 'userSessions',
          type: 'line',
          stacked: false,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            show: false,
            download: false,
            autoSelected: 'pan'
          },
          redrawOnParentResize: true,
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler()
            }
          },
          animations: {
            enabled: true,
            easing: 'linear',
            speed: 1000,
            dynamicAnimation: {
              enabled: true,
              speed: 1000
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          colors: ['#16a085']
        },
        xaxis: {
          type: 'datetime'
        },
        tooltip: {
          shared: false
        },
        noData: {
          text: 'No data'
        },
        theme: {
          mode: 'light'
        }
      },
      series: [],
      data: []
    }
  }),
  computed: {
    userRolesTitle () {
      const role = this.roles.filter(role => { return role.key === this.$store.getters.doGetRole })

      if (role.length >= 1) {
        return role[0].name
      } else {
        return null
      }
    }
  },
  methods: {
    doLoadRoles () {
      new Promise((resolve, reject) => {
        this.$services.role.doGetAll().then(roles => {
          this.roles = roles
        }).catch(error => {
          reject(error)
        })
      }).then(_ => {

      }).catch(error => {
        this.$root.$emit('showError', error.message)
      })
    },
    doGetSessions () {
      this.userSessions.isLoading = true

      this.$services.stats.doGetAllSessions().then(stats => {
        this.userSessions.data = stats
        this.userSessions.series = [{
          name: 'User Sessions',
          data: this.userSessions.data.map(stat => { return stat.count })
        }]

        this.userSessions.options.xaxis.categories = this.userSessions.data.map(stat => { return stat.date })

        const startZoom = this.$moment(new Date()).subtract(1, 'days').toDate().getTime()
        const endZoom = new Date().getTime()

        this.$nextTick(() => {
          try {
            this.$refs.userSessionChart.zoomX(startZoom, endZoom)
          } catch (e) {
            console.log(e)
          }
        })
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.$refs.userSessionChart.refresh()
        this.userSessions.isLoading = false
      })
    },
    doApplyDarkThemeForChart () {
      if (this.$vuetify.theme.isDark) {
        this.userSessions.options.theme.mode = 'dark'
      }
    },
    doGoToTransaction (uuid) {
      this.$router.replace({ name: 'admin-view-transactions', params: { uuid: uuid } })
    }
  },
  mounted () {
    this.doLoadRoles()
    this.hideAdmin = (this.$store.getters.doGetRole === 'employee')

    if (!this.hideAdmin) {
      // Admin only
      this.doGetSessions()
    }
  },
  created () {
    this.doApplyDarkThemeForChart()
  }
}
</script>

<style scoped>
.blurry {
  filter: blur(20px);
  -webkit-filter: blur(20px);
  cursor: pointer !important;
  pointer-events: none !important;
}
</style>
